import React, { useEffect, useState } from "react";
import "./Referrals.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch } from "../../Hooks";
import { useSnackbar } from "notistack";
import { setVendorDetails } from "../../store/reducers/VendorDetailsReducer";
import { useSelector } from "react-redux";

let UserCodeData: any;
function Referrals() {
  const { VendorList } = useSelector((state: any) => state.vendorDetails);
  const [username, setUserName] = useState<string>("");
  const [useremail, setUseremail] = useState<string>("");
  const [referredemail, setRefferedEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [specificURL, setSpecificURL] = useState(
    // "http://localhost:3000/createprofile/"
    "https://vendor.miniosk.com/createprofile/"
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const referralCode = localStorage.getItem("referralCode");
    setSpecificURL(specificURL + referredemail + "/" + referralCode);
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const VendorData = async () => {
      if (UserCodeData) {
        try {
          const response = await HelperModule().getMethod(
            ApiServices.base_URL + ApiServices.get_vendor + UserCodeData
          );
          if (response.status === true) {
            dispatch(setVendorDetails(response.data));
            console.log("vendorDetails", response.data);
          } else {
            console.log("Error");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    VendorData();
  }, [UserCodeData, dispatch]);

  const HandleReferral = async () => {
    if (VendorList?.vendor_details?.email === referredemail) {
      enqueueSnackbar("Referal Email cannot be same", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else {
      const Params = {
        user_name: VendorList?.vendor_details?.name,
        user_email: VendorList?.vendor_details?.email,
        referred_to_email: referredemail,
      };

      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL +
            ApiServices.verify_refferal +
            `?user_code=${UserCodeData}`,
          Params
        );
        if (response.status === true) {
          setSpecificURL(specificURL + response.data.referral_code);
          enqueueSnackbar("Referal Code has been sent successfully", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          console.log("Successs response", response);
        } else {
          enqueueSnackbar("Refered Email is missing", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          console.log("failed response", response);
        }
      } catch (error) {
        enqueueSnackbar("Incorrect password", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.error(error);
      }
    }
  };

  const copySpecificURLToClipboard = () => {
    if(referredemail){
      navigator.clipboard
      .writeText(specificURL)
      .then(() => {
        enqueueSnackbar("URL copied to clipboard", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      })
      .catch((error) => {
        enqueueSnackbar("Failed to copy URL to clipboard", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.error(error);
      });
    }else{
      enqueueSnackbar("Enter referral email", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
    
  };

  const openEmailClient = () => {
    const fromEmail = encodeURIComponent(VendorList?.vendor_details?.email);
    const subject = encodeURIComponent("Referral Invitation from Miniosk");
    const body = encodeURIComponent(
      `Hi,\n\nI wanted to share Miniosk with you! Join using my referral link: ${specificURL}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}&from=${fromEmail}`;
    // window.location.href = mailtoLink;
    window.open(mailtoLink, "_blank");
  };

  return (
    <div>
      <h1 className="heading-referrals">Referrals</h1>
      <div className="reffer-container">
        <div className="text-refer-cont">
          <p className="refer-buddy">Refer A Buddy</p>
          <p className="reffer-about">
            Earn a reward for each person you refer to Miniosk!
          </p>
        </div>
        <div className="btn-container-referral">
          <button
            className="btn-url mr-[20px]"
            onClick={copySpecificURLToClipboard}
            style={{ transition: "background-color 0.3s" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Copy URL
          </button>
          <button
            className="btn-url"
            onClick={openEmailClient}
            style={{ transition: "background-color 0.3s" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Share URL
          </button>
        </div>
      </div>
      <div className="maincontainer-referrel">
        <div className="container-referrel">
          <label className="label-input" htmlFor="username">
            What's Your Name?
          </label>
          <div>
            <input
              readOnly={true}
              type="text"
              placeholder="Name"
              id="username"
              name="username"
              value={VendorList?.vendor_details?.name}
              onChange={(e) => setUserName(e.target.value)}
              className="referral-input"
              autoComplete="name"
            />
          </div>
        </div>
        <div className="container-referrel">
          <label className="label-input" htmlFor="useremail">
            What's Your Email?
          </label>
          <p className="about-referrel">
            What email should we contact you at for your reward?
          </p>
          <div>
            <input
              readOnly={true}
              type="text"
              placeholder="Email"
              id="useremail"
              name="useremail"
              value={VendorList?.vendor_details?.email}
              onChange={(e) => setUseremail(e.target.value)}
              className="referral-input"
              autoComplete="email"
            />
          </div>
        </div>
        <div className="container-referrel">
          <label className="label-input" htmlFor="useremail">
            What Did You Refer To Miniosk?
          </label>
          <p className="about-referrel">
            We need this to validate your referral, so any and all info helps!
            For example, their full name, business name, or email they signed up
            with.
          </p>
          <div>
            <input
              type="text"
              placeholder="referredEmail"
              id="referredemail"
              name="refferedemail"
              onChange={(e) => setRefferedEmail(e.target.value)}
              className="referral-input"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="container-referrel">
          <p className="label-input">What Type Of Reward Would You Like?</p>
          <button className="paymentType-btn">
            Credit Towards My Miniosk Subscription
          </button>
        </div>
        <button
          onClick={HandleReferral}
          className="sendans-btn"
          style={{ transition: "background-color 0.3s" }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
          }}
        >
          Send Answers
        </button>
      </div>
    </div>
  );
}
export default Referrals;
